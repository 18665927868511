import { IAddSaleOrderModuleTypes, ISaleOrder, ISaleOrderTypeRes } from "./types";

export const ActionTypes = {
  SET_STORE_SALE_ORDER_MODULE_LIST: "SET_STORE_SALE_ORDER_MODULE_LIST",
  SET_UPDATE_STATUS:"SET_UPDATE_STATUS",
  SET_DELETE_ORDER:"SET_DELETE_ORDER",
  SET_PRODUCT_DETAILS:"SET_PRODUCT_DETAILS",
  SET_STORE_SALE_REPORT_TOGGLE_DETAILS:"SET_STORE_SALE_REPORT_TOGGLE_DETAILS",
};

export const setSaleOrderModuleList = (payload: ISaleOrderTypeRes) => ({
  type: ActionTypes.SET_STORE_SALE_ORDER_MODULE_LIST,
  payload,
});
export const setSaleUpdateStatus = (payload: ISaleOrder) => ({
  type: ActionTypes.SET_UPDATE_STATUS,
  payload,
});
export const setDeleteOrder = (payload: ISaleOrder) => ({
  type: ActionTypes.SET_DELETE_ORDER,
  payload,
});
export const setStoreCustomersDetails = (payload: ISaleOrderTypeRes) => ({
  type: ActionTypes.SET_UPDATE_STATUS,
  payload,
});

export const setSalesProductDetails = (payload?: ISaleOrderTypeRes | IAddSaleOrderModuleTypes) => ({
  type: ActionTypes.SET_PRODUCT_DETAILS,
  payload,
});
export const setStoreSaleReportToggleDetails = (payload?: {id:string,data?:IAddSaleOrderModuleTypes}) => ({
  type: ActionTypes.SET_STORE_SALE_REPORT_TOGGLE_DETAILS,
  payload,
});