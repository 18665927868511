/* eslint-disable array-callback-return */

import { ActionTypes } from './action';
import { IPurchaseOrderTypeRes } from "./types";


const initialState: IPurchaseOrderTypeRes = {
  list: {
    optionsList: [],
    items: [],
    totalCount: 0,
  },
  purchasedetails: null,
  poReportDetails:null,

};

const PurchaseOrderReducer = (state: IPurchaseOrderTypeRes = initialState, action): IPurchaseOrderTypeRes => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_PURCHASE_ORDER_MODULE_LIST: {
      const optionsList = action?.payload?.items?.map?.((item, index) => ({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        list: action?.payload ? {
          ...action?.payload,
          optionsList: optionsList,
        }: initialState.list,
      };
    }
    case ActionTypes.SET_UPDATE_STATUS: {
      const updatedList = state?.list?.items.map(
        (item) => item.id === action.payload?.id ? { ...item, status: action?.payload?.status } : item
      );
      return {
        ...state,
        list: { items: updatedList }
      };
    }
    case ActionTypes.SET_DELETE_ORDER: {
      const updatedList = state.list?.items.filter(item => item.id !== action.payload.id);
      const totalCount = state?.list?.totalCount - 1;
      return {
        ...state,
        list: { items: updatedList, totalCount: totalCount },

      };
    }
    case ActionTypes.SET_PRODUCT_DETAILS: {
      // console.log("ACTION", action?.payload);

      return {
        ...state,
        purchasedetails: action?.payload
      };
    }
    case ActionTypes.SET_STORE_PO_REPORT_TOGGLE_DETAILS:{
      return{
        ...state,
        poReportDetails:{
          ...state?.poReportDetails,
          [action?.payload?.id]:action?.payload?.data,
        }
      }
    }
    default:
      return state;
  }
};

export default PurchaseOrderReducer;
