/* eslint-disable @typescript-eslint/no-unused-vars */
import { GroupIdList, IChatGroup, IEmoji, IGroup, IGroupIdData, IMessage, IMessageListType, INotification, IUsers } from "./types";

export const ActionTypes = {
  SET_STORE_UPDATE_USER_CHAT:"SET_STORE_UPDATE_USER_CHAT",
  SET_STORE_MESSAGE: "SET_STORE_MESSAGE",
  RECIVE_MESSAGE: "RECIVE_MESSAGE",
  SET_STORE_CHANNEL_DETAILS: "SET_STORE_CHANNEL_DETAILS",
  UPDATE_STORE_CHANNEL_DETAILS: "UPDATE_STORE_CHANNEL_DETAILS",
  ADD_USER_TO_CHAT_LIST: "ADD_USER_TO_CHAT_LIST",
  SET_ADD_NOTIFICATION_LIST: "SET_ADD_NOTIFICATION_LIST",
  SHOW_CHAT_NOTIFICATION:"SHOW_CHAT_NOTIFICATION",
  HIDE_CHAT_NOTIFICATION:"HIDE_CHAT_NOTIFICATION",
  SET_STORE_MESSAGE_LIST: "SET_STORE_MESSAGE_LIST",
  SET_STORE_GROUP_ADD:"SET_STORE_GROUP_ADD",
  SET_FILTERED_GROUPS:"SET_FILTERED_GROUPS",
  ADD_GROUP_TO_LIST:"ADD_GROUP_TO_LIST",
  SET_CREATE_PROJECT_GROUP:"SET_CREATE_PROJECT_GROUP",
  ADD_PROJECT_GROUP_LIST:"ADD_PROJECT_GROUP_LIST",
  ADMIN_LOGOUT:"ADMIN_LOGOUT",
  SET_STORE_UPDATE_SINGLE_CHAT_USERS_LIST:"SET_STORE_UPDATE_SINGLE_CHAT_USERS_LIST",
  SET_STORE_ADD_USER_CHAT:"SET_STORE_ADD_USER_CHAT",
  SET_STORE_UPDATE_CHAT_COUNT:"SET_STORE_UPDATE_CHAT_COUNT",
  SET_STORE_UPDATE_CHAT_MESSAGE:"SET_STORE_UPDATE_CHAT_MESSAGE",
  SET_STORE_UPDATE_CHAT_USERS:"SET_STORE_UPDATE_CHAT_USERS",
  
  EMOJI_LIST_GET:"EMOJI_LIST_GET",
  SET_STORE_CHANGE_GROUP_NAME:"SET_STORE_CHANGE_GROUP_NAME",
  SET_REMOVE_USER_FROM_GROUP:"SET_REMOVE_USER_FROM_GROUP",
  SET_ADD_USER_TO_GROUP:"SET_ADD_USER_TO_GROUP",
  SET_GROUP_DETAILS:"SET_GROUP_DETAILS",
};

export const setStoreMessageList = (payload: {data:IMessageListType, group_id: string}) => ({
  type: ActionTypes.SET_STORE_MESSAGE_LIST,
  payload,
});
export const addUserToChatList = (payload: IUsers) => ({
  type: ActionTypes.ADD_USER_TO_CHAT_LIST,
  payload,
});
export const addGroupToList = (payload: IGroup) => ({
  type: ActionTypes.ADD_GROUP_TO_LIST,
  payload,
});

export const setStoreMessage = (payload: {data:IMessage, group_id?:string}) => ({
  type: ActionTypes.SET_STORE_MESSAGE,
  payload,
});

export const setStoreUpdateUserChat = (payload: { id: string; group_id: string }) => ({
  type: ActionTypes.SET_STORE_UPDATE_USER_CHAT,
  payload,
});

export const setStoreChannelDetails = (chat: IMessage) => ({
  type: ActionTypes.SET_STORE_CHANNEL_DETAILS,
  payload: chat, 
});

export const setUpdateChannelDetails = (chat: IMessage) => ({
  type: ActionTypes.UPDATE_STORE_CHANNEL_DETAILS,
  payload: chat, 
});
export const setAddNotificationList = (payload: INotification) => ({
  type: ActionTypes.SET_ADD_NOTIFICATION_LIST,
  payload,
});

export const showChatNotification = () => ({
  type: ActionTypes.SHOW_CHAT_NOTIFICATION,
});

export const hideChatNotification = () => ({
  type: ActionTypes.HIDE_CHAT_NOTIFICATION,
});
export const setStoreGroupAdd=(payload: IGroup)=>({
  type: ActionTypes.SET_STORE_GROUP_ADD,
  payload,
});
export const setFilteredGroups=(payload:GroupIdList)=>({
  type:ActionTypes.SET_FILTERED_GROUPS,
  payload,
})
export const setCreateProjectGroup=(payload: IGroup)=>({
  type: ActionTypes.SET_CREATE_PROJECT_GROUP,
  payload,
});
export const addProjectGroupList = (payload: IGroup) => ({
  type: ActionTypes.ADD_PROJECT_GROUP_LIST,
  payload,
});
export const setStoreUpdateSingleChatUsersList = (payload?: {users_list?:any[], chat_list?:any[],current_user_id: string }) => ({
  type: ActionTypes.SET_STORE_UPDATE_SINGLE_CHAT_USERS_LIST,
  payload,
})
export const setStoreAddUserChat=(payload: IGroup)=>({
  type: ActionTypes.SET_STORE_ADD_USER_CHAT,
  payload,
});
export const setStoreUpdateChatCount=(payload?: {data:IGroup, group_type:string})=>({
  type: ActionTypes.SET_STORE_UPDATE_CHAT_COUNT,
  payload,
});
export const setStoreUpdateChatMessage=(payload: IGroup)=>({
  type: ActionTypes.SET_STORE_UPDATE_CHAT_MESSAGE,
  payload,
});
export const setStoreUpdateChatUsers=(payload: {data:IGroup, group_type:string})=>({
  type: ActionTypes.SET_STORE_UPDATE_CHAT_USERS,
  payload,
});
export const emojiListData=(payload: IEmoji[])=>({
  type:ActionTypes.EMOJI_LIST_GET,
  payload,
})
export const setStoreChangeGroupName=(payload: IGroup)=>({
  type: ActionTypes.SET_STORE_CHANGE_GROUP_NAME,
  payload,
});
export const setRemoveUserFromGroup=(payload: IGroup)=>({
  type:ActionTypes.SET_REMOVE_USER_FROM_GROUP,
  payload,
})
export const setAddUserToGroup=(payload: IGroup)=>({
  type:ActionTypes.SET_ADD_USER_TO_GROUP,
  payload,
})
export const setGroupDetails=(payload:IGroup)=>({
  type:ActionTypes.SET_GROUP_DETAILS,
  payload,
})
