import { IAddShop, IShopOrdersRes } from "./types";

export const ActionTypes = {
  SET_STORE_SHOP_LIST: "SET_STORE_SHOP_LIST",
  SET_STORE_SHOP_DETAILS:"SET_STORE_SHOP_DETAILS",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  SET_STORE_SHOP_SCHEDULE_LIST:"SET_STORE_SHOP_SCHEDULE_LIST",
  SET_STORE_SHOP_DELIVERED_LIST:"SET_STORE_SHOP_DELIVERED_LIST",
  DELETE_SHOP_SUPPLY_ORDER:"DELETE_SHOP_SUPPLY_ORDER",
  UPDATE_SHOP_SUPPLY_LIST:"UPDATE_SHOP_SUPPLY_LIST"
};

export const setStoreShopList = (payload: IShopOrdersRes) => ({
  type: ActionTypes.SET_STORE_SHOP_LIST,
  payload,
});
export const setStoreShopDetails = (payload?: {id?:string,data?:IAddShop}) => ({
  type: ActionTypes.SET_STORE_SHOP_DETAILS,
  payload,
});
export const setStoreShopScheduleList = (payload: IShopOrdersRes) => ({
  type: ActionTypes.SET_STORE_SHOP_SCHEDULE_LIST,
  payload,
});
export const setStoreShopDeliveredList = (payload: IShopOrdersRes) => ({
  type: ActionTypes.SET_STORE_SHOP_DELIVERED_LIST,
  payload,
});


export const setUpdateShopDeliveredList = (payload: IAddShop) => ({
  type: ActionTypes.UPDATE_SHOP_SUPPLY_LIST,
  payload,
});
export const deleteShopSupplyDelivery = (payload: string) => ({
  type: ActionTypes.DELETE_SHOP_SUPPLY_ORDER,
  payload: payload,
});