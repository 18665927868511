/* eslint-disable array-callback-return */

import { ActionTypes } from './action';
import { ISaleOrderTypeRes } from "./types";


const initialState: ISaleOrderTypeRes = {
  list: {
    optionsList: [],
    items: [],
    totalCount: 0,
  },
  saledetails: null,
  saleReportDetails: null,

};

const saleOrderReducer = (state: ISaleOrderTypeRes = initialState, action): ISaleOrderTypeRes => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_SALE_ORDER_MODULE_LIST: {
      const optionsList = action?.payload?.items?.map((item, index) => ({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        list: {
          ...action?.payload,
          optionsList: optionsList,
        }

      };
    }
    case ActionTypes.SET_UPDATE_STATUS: {
      const updatedList = state?.list?.items.map(
        (item) => item.id === action.payload?.id ? { ...item, status: action?.payload?.status } : item
      );
      return {
        ...state,
        list: { ...action?.payload, items: updatedList }
      };
    }
    case ActionTypes.SET_DELETE_ORDER: {
      const updatedList = state.list?.items.filter(item => item.id !== action.payload.id);
      const totalCount = state?.list?.totalCount - 1;
      return {
        ...state,
        list: {
          ...action?.payload,
          items: updatedList,
          totalCount: totalCount
        }
      };
    }
    case ActionTypes.SET_PRODUCT_DETAILS: {
      // console.log("ACTION", action?.payload);
      return {
        ...state,
        saledetails: action?.payload
      };
    }
    case ActionTypes.SET_STORE_SALE_REPORT_TOGGLE_DETAILS:{
      return{
        ...state,
        saleReportDetails:{
          ...state?.saleReportDetails,
          [action?.payload?.id]:action?.payload?.data,
        }
      }
    }
    default:
      return state;
  }
};

export default saleOrderReducer;
