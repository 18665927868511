/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionTypes } from './action';
import { IComplexityRes } from "./types";


const initialState: IComplexityRes = {
    items: [],
    totalCount: 0,
    optionList:[]
};

const complexityReducers = (state: IComplexityRes = initialState, action): IComplexityRes => {
  switch (action?.type) {
    case ActionTypes.COMPLEXITY_LIST:{
      const optionList = action?.payload?.items?.map((item: any, index: any)=>{
        return{
          value: item.id,
          label: item.name,
          status: item.active
        }
      })
      return {
        ...state,
        ...action?.payload,
        optionList: optionList,
      };
    }
    case ActionTypes.ADD_COMPLEXITY:{
      const items = [];
      items.push(action?.payload);
      state?.items?.map((item, index)=>{
        items.push(item);
      })
      const totalCount = state?.totalCount + 1;
      return {
        ...state,
        items: items,
        totalCount: totalCount,
      };
    }
    case ActionTypes.UPDATE_COMPLEXITY:{
      const updatedList = state?.items.map(item =>
        item.id === action?.payload.id ? action?.payload : item
      );
      return {
        ...state,
        items: updatedList,
      };
    }
    case ActionTypes.DELETE_COMPLEXITY:{
      const updatedList = state.items.filter(item => item.id !== action.payload?.request?.payload?.id);
      const totalCount = state?.totalCount - 1;
      return {
        ...state,
        items: updatedList,
        totalCount: totalCount
      };
    }
   
    default:
      return state;
  }
};

export default complexityReducers;
