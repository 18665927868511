import { IPORes, IPOTypes } from "../distribution/types";
import { IAging, ICollectionRes, InventoryRecord } from "./types";

export const ActionTypes = {
  SET_STORE_COLLECTION_LIST: "SET_STORE_COLLECTION_LIST",
  INVENTORY_COLLECTION_LIST: "INVENTORY_COLLECTION_LIST",
  AGING_LIST: "AGING_LIST",
  SET_PO_LIST:"SET_PO_LIST",
  SET_PO_REPORT_DETAILS:"SET_PO_REPORT_DETAILS"

};

export const setStoreCollectionList = (payload: ICollectionRes) => ({
  type: ActionTypes.SET_STORE_COLLECTION_LIST,
  payload,
});

export const setInventoryReportList = (payload: InventoryRecord[]) => ({
  type: ActionTypes.INVENTORY_COLLECTION_LIST,
  payload,
});


export const setAgingReportList = (payload: IAging[]) => ({
  type: ActionTypes.AGING_LIST,
  payload,
});


export const setPOReport = (payload:IPORes) => ({
  type: ActionTypes.SET_PO_LIST,
  payload,
});


export const setPOReportDetails = (payload:IPOTypes) => ({
  type: ActionTypes.SET_PO_REPORT_DETAILS,
  payload,
});