/* eslint-disable array-callback-return */

import { ActionTypes } from "./action";
import { ICollectionRes } from "./types";

const initialState: ICollectionRes = {
  collectionList: {
    optionsList: [],
    parsedItems: [],
    totalCount: 0,
  },
  poReportList: {
    items: [],
    distribution: [],
    purchase: [],
    totalCount: 0,
  },
  inventoryReportList: [],
  agingList: [],
  payload: null,
  poReportDetails:null
};

const collectionReducers = (
  state: ICollectionRes = initialState,
  action
): ICollectionRes => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_COLLECTION_LIST: {
      return {
        ...state,
        collectionList: {
          ...action?.payload,
        },
      };
    }
    case ActionTypes.AGING_LIST: {
      return {
        ...state,
        agingList: {
          ...action?.payload,
        },
      };
    }
    case ActionTypes.INVENTORY_COLLECTION_LIST: {
      return {
        ...state,
        inventoryReportList: {
          ...action?.payload,
        },
      };
    }

    case ActionTypes.SET_PO_LIST: {
      return {
        ...state,
        poReportList: {
          ...action?.payload,
        },
      };
    }

    case ActionTypes.SET_PO_REPORT_DETAILS:{
          return{
            ...state,
            poReportDetails:{
              ...state?.poReportDetails,
              [action?.payload?.id]:action?.payload,
            }
          }
        }
    default:
      return state;
  }
};

export default collectionReducers;
